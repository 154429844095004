:root {
  /* ------- COLORS ------- */
  --COLOR_GRAY_LIGHT: #e5e5e5;
  --COLOR_WHITE: #fff;
  --COLOR_BLUE_LIGHT: #3081e533;
  --COLOR_PRIMARY: #1890ff;
  --COLOR_BACKGROUND: #f5f5f5;
  --COLOR_RED_LIGHT: #f51d2c19;
  --COLOR_RED: #ff4d4f;
  --COLOR_GREEN: #33cc33;
  --COLOR_WHITE: #ffffff;
  --COLOR_BLACK: #000;
  --COLOR_BLACK_85: rgba(0, 0, 0, 0.85);
  --COLOR_BLACK_45: rgba(0, 0, 0, 0.45);
  --COLOR_GRAY: #d9d9d9;
  --COLOR_DARK_GRAY: #adadad;
  --COLOR_VERY_DARK_GRAY: #808080;
  --BACKGROUND_IMAGE: url('src/assets/images/bg.png');

  /* NEW COLORS */
  --COLOR_WHITE_10: rgba(255, 255, 255, 0.1);
  --COLOR_WHITE_15: rgba(255, 255, 255, 0.15);

  --COLOR_BLACK_15: rgba(0, 0, 0, 0.15);
  --COLOR_BLACK_25: rgba(0, 0, 0, 0.25);
  --COLOR_BLACK_9: #181818;
  --COLOR_DARK: #31343c;
  --COLOR_DRAWER_MASK: rgba(49, 52, 60, 0.8);
  --COLOR_BOX_SHADOW: rgba(0, 0, 0, 0.03);

  --COLOR_GRAY_NEW: #8d8d8d;
  --COLOR_GRAY_5: rgba(128, 128, 128, 0.5);
  --COLOR_DARK_GRAY_5: rgba(83, 83, 83, 0.5);
  --COLOR_GRAY_LIGHTER_NEW: #ececec;
  --COLOR_GRAY_BUTTON_BORDER_NEW: #545b69;
  --COLOR_GRAY_LIGHT_NEW: #eaeaea;
  --COLOR_GRAY_BUTTON_NEW: #efefef;
  --CLOR_GRAY_LIGHT_2_NEW: #f2f3f7;
  --COLOR_GRAY_BUTTON_HOVER_NEW: #f3f3f3;
  --COLOR_GRAY_DARK_NEW: #31343b;
  --COLOR_GRAY_DARK_BORDER_NEW: #4f4f4f;
  --COLOR_MID_DARK_GRAY: #747474;

  --COLOR_PRIMARY_NEW: #3081e5;
  --COLOR_PRIMARY_HOVER_NEW: #4a98f9;
  --COLOR_BLUE_LIGHT_NEW: #afccff;
  --COLOR_BLUE_DISABLED: #baccec;
  --COLOR_BLUE_MESSAGE: #f4f9ff;
  --COLOR_GREEN_MESSAGE: #d6fac4;
  --COLOR_RED_NEW: #fc6767;
  --COLOR_DARK_RED: #eb1d1d;
  --COLOR_MID_DARK_RED: #ea4335;
  --COLOR_ORANGE: #ffa500;
  --COLOR_YELLOW: #fff6e0;

  /* ------- ELEMENT SIZES ------- */
  --ROOT_HEIGHT: 100vh;
  --ROOT_WIDTH: 100vw;

  /* Input styles */
  --INPUT_WIDTH: 18.4vw;

  /* Padding styles */
  --ROOT_PADDING: 1.25rem;

  /* Font size styles */
  --ROOT_FONT_SIZE: 1.25rem;
  --ICON_FONT_SIZE: 0.875rem;
  --ICON_FONT_SIZE_BIG: 1rem;

  /* ------- FONT FAMILY ------- */
  --FONT_TITLE: 'Montserrat';

  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */

  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace; */

  /* ------- FONT SIZES ------- */
  --FONT_SIZE_12: 12px;
  --FONT_SIZE_14: 14px;
  --FONT_SIZE_16: 16px;
  --FONT_SIZE_18: 18px;
  --FONT_SIZE_20: 20px;
  --FONT_SIZE_24: 24px;
  --FONT_SIZE_26: 26px;
  --FONT_SIZE_28: 28px;
  --FONT_SIZE_34: 34px;
  --FONT_SIZE_36: 36px;
  --FONT_SIZE_38: 38px;
  --FONT_SIZE_40: 40px;
  --FONT_SIZE_44: 44px;
  --FONT_SIZE_48: 48px;
  --FONT_SIZE_62: 62px;
  --FONT_SIZE_64: 64px;

  /* 0.8rem */
  /* 0.85rem */
  /* 0.875rem */
  /* 1rem */
  /* 1.2rem */
  /* 1.3rem */
  /* 1.5rem */
  /* 2rem */
  /* 2.5rem */

  /* 90% */

  /* 20px */

  /* ------- FONT WEIGHTS ------- */
  --FONT_THIN: 200;
  --FONT_LIGHT: 300;
  --FONT_NORMAL: 400;
  --FONT_MEDIUM: 500;
  --FONT_BOLD: 700;

  /* ------- LINE HEIGHT ------- */
  --LINE_HEIGHT_20: 20px;
  --LINE_HEIGHT_24: 24px;
  --LINE_HEIGHT_120: 120%;
  --LINE_HEIGHT_150: 150%;
  /* 1.5715 */
  /* 1.6 */
  /* 1.8 */

  /* 1.2rem */

  /* ------- BORDER  ------- */
  --BORDER: 2px solid var(--COLOR_GRAY_LIGHT_NEW);
  --BORDER_FOCUS: 2px solid var(--COLOR_BLUE_LIGHT_NEW);

  /* ------- BORDER RADIUS ------- */
  --BORDER_RADIUS_10: 10px;
  --BORDER_RADIUS_15: 15px;
  --BORDER_RADIUS_20: 20px;
  --BORDER_RADIUS_FULL: 50%;
  /* 0 */
  /* 0.3rem */
  /* 0.6rem */
  /* 0.625rem */
  /* 1rem */

  /* 50% */

  /* ------- BOX SHADOW ------- */
  --BOX_SHADOW_DRAWER: 0px 20px 40px 0px var(--COLOR_BOX_SHADOW);
  --BOX_SHADOW_DROPDOWN: 2px 2px 20px 0px var(--COLOR_BLACK_15);
  /* 0 0 0.4rem 0 var(--COLOR_DARK_GRAY) */
  /* 0 0 0.7rem 0 var(--COLOR_DARK_GRAY) */
}
