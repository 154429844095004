@font-face {
  font-family: 'Montserrat';
  src: url('src/assets/fonts/Montserrat-Bold.ttf');
  font-style: normal;
  font-weight: var(--FONT_BOLD);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Inter';
  src: url('src/assets/fonts/Inter-Light.ttf');
  font-style: normal;
  font-weight: var(--FONT_LIGHT);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Inter';
  src: url('src/assets/fonts/Inter-Regular.ttf');
  font-style: normal;
  font-weight: var(--FONT_NORMAL);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: var(--FONT_NORMAL);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
  list-style-type: none;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container_large {
  max-width: 1920px;
  margin: 0 auto;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  background-clip: text !important;
}

.errorText {
  color: var(--COLOR_RED_NEW);
  text-align: left;
  padding-top: 8px;
}

.fieldWrapper {
  position: relative;
  padding-top: 10px;
  width: 100%;
}

.fieldName {
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 1;
  padding: 0 4px;
  color: var(--COLOR_GRAY_NEW);
  font-size: var(--FONT_SIZE_16);
  line-height: 100% !important;
  letter-spacing: 0.16px;
  background-color: var(--COLOR_WHITE);
  border-radius: 4px;
}

@media all and (min-width: 1500px) {
  .container {
    padding: 0;
  }
}
