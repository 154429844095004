.articleTitle {
  /* color: var(--COLOR_PRIMARY); */
  font-size: var(--FONT_SIZE_28);
  font-weight: var(--FONT_BOLD);
  line-height: var(--LINE_HEIGHT_120);
}

.articleDescription {
  color: var(--COLOR_MID_DARK_GRAY);
  font-style: italic;
  font-size: var(--FONT_SIZE_18);
  font-weight: var(--FONT_NORMAL);
  line-height: var(--LINE_HEIGHT_150);
  margin-top: 16px;
}

.mdText {
  margin-top: 24px;
  line-height: var(--LINE_HEIGHT_150);
}

.mdText p,
.mdText ul,
.mdText ol {
  margin: 24px 0;
}

.mdText li > p {
  margin: 0;
}

.mdText ol li {
  list-style-type: decimal;
}

.mdText ul li {
  list-style-type: disc;
}
