[class*='ant-drawer-mask'] {
  background-color: var(--COLOR_DRAWER_MASK) !important;
}

[class*='ant-drawer-content-wrapper'] {
  inset: 16px !important;
  overflow: hidden;
  max-width: calc(100% - 36px) !important;
  box-shadow: var(--BOX_SHADOW_DRAWER) !important;
}

.drawer {
  border-radius: var(--BORDER_RADIUS_20);
}

.drawer [class='ant-drawer-header'] {
  padding: 16px;
  border-bottom: var(--BORDER);
}

.drawer [class='ant-drawer-title'] {
  font-size: var(--FONT_SIZE_24);
  line-height: 120%;
  color: var(--COLOR_BLACK_9);
}

.drawer [class*='ant-drawer-header-title'] {
  flex-direction: row-reverse;
  padding: 0;
}

.drawer [class*='ant-drawer-header-title'] button {
  display: flex;
  margin: 0;
  padding: 0;
  color: var(--COLOR_GRAY_NEW);
  transition: color 0.2s;
}

.drawer [class*='ant-drawer-header-title'] button:hover {
  color: var(--COLOR_PRIMARY_NEW);
}

.drawer [class*='ant-drawer-body'] {
  padding: 24px 16px 16px;
}

.drawer_menu {
  max-width: 360px;
  margin-right: 0;
  margin-left: auto;
}

.drawer_menu [class*='ant-drawer-header'] {
  border-bottom: none;
  padding-top: 24px;
  padding-bottom: 12px;
}

.drawer_menu [class*='ant-drawer-header-title'] {
  padding: 0;
}

.drawer_menu [class*='ant-drawer-body'] {
  padding: 0 !important;
}

@media screen and (min-width: 500px) {
  [class*='ant-drawer-content-wrapper'] {
    inset: 24px !important;
    max-width: calc(100% - 48px) !important;
  }

  .drawer [class='ant-drawer-header'] {
    padding: 24px;
  }

  .drawer [class*='ant-drawer-body'] {
    padding: 32px 24px 32px;
  }
}

@media screen and (min-width: 992px) {
  [class*='ant-drawer-content-wrapper'] {
    left: auto !important;
  }

  .drawer [class*='ant-drawer-body'] {
    padding-top: 32px;
  }
}
